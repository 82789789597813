























import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import { AxiosInstance } from 'axios'

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    rules: {
      type: Array,
      required: false,
      default: () => []
    },
    companies: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  setup(props, { root, emit }) {
    const entries = ref<any[]>(props.companies)
    const isLoading = ref(false)
    const model = ref(props.value)
    const search = ref(null)
    const count = ref(0)

    const fields = computed(() => {
      if (!model.value) return []

      return Object.keys(model.value as any).map(key => ({ key, value: (model.value as any)[key] || 'n/a' }))
    })
    const items = computed(() => entries.value)

    watch(() => search.value, val => {
      if (isLoading.value) return

      if (val) {
        isLoading.value = true

        const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance

        axiosInstance
          .get('company', { params: { search: val } })
          .then(({ data }) => {
            const { companies, total } = data
            count.value = total

            const ids = new Set(entries.value.map(e => e.id))
            entries.value = [...entries.value, ...companies.filter((c: any) => !ids.has(c.id))]
          })
          .catch(error => console.log(error))
          .finally(() => isLoading.value = false)
      }
    })

    watch(() => model.value, val => emit('input', val))

    return { entries, isLoading, model, search, fields, items }
  }
})
